import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Faq } from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import { useFaq } from 'src/hooks/componentData';

/* eslint-disable react/prop-types */
function WebformAwsThankYouPage({ location }) {
  const faq = useFaq('faq');

  return (
    <PageLayout>
      <PageSEO title="Aws könyv köszönjük" description="AWS könyv köszönjük" location={location}>
        {/* This page should not be indexed */}
        <meta name="robots" content="noindex" />
      </PageSEO>
      <Container fluid className="bg-shapes-banner px-0 pt-7">
        <Container className="py-8">
          <Row className="align-items-center">
            <Col xs={12} md={8} lg={5} className="mb-3">
              <h1 className="h2 mb-4">AWS Könyv magyarul</h1>
              <p className="text-muted mb-5">
                Köszönjük az űrlapkitöltést, a könyv elérhetőségi linkjét a megadott e-mail címre továbbítjuk, amennyiben ez nem érkezne
                meg, kérjük vegye fel velünk a kapcsolatot az &nbsp;
                <a className="font-weight-bold" href="mailto:aws@cheppers.com">
                  aws@cheppers.com
                </a>
                &nbsp; címen.
              </p>
              <Link to="/szolgaltatasok/cheppers-cloud" className="btn-link-primary">
                Mutasd a felhőszolgáltatásainkat
              </Link>
            </Col>
            <Col xs={12} lg={7} className="d-none d-lg-block">
              <div className="mw-sm mx-auto">
                <StaticImage src="../images/thank-you.png" alt="image" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pt-8 text-center">
              <Link to="/" className="btn btn-gradient-primary">
                Vissza a főoldalra
              </Link>
            </Col>
          </Row>
        </Container>
        {faq && <Faq {...faq} />}
      </Container>
    </PageLayout>
  );
}

export default WebformAwsThankYouPage;
